import {
  each
} from 'lodash'
import anime from 'animejs'
import disableScroll from 'disable-scroll'


class Modal {
  constructor(el) {
    this.DOM = {
      el: el
    }
    this.DOM.buttons = Array.from(document.querySelectorAll('.btn--modal'))
    this.DOM.closeBtn = document.querySelector('.c-modal__close')
    this.DOM.pills = this.DOM.el.querySelectorAll('.o-pill')
    this.DOM.labels = this.DOM.el.querySelectorAll('.c-label')
    this.DOM.links = this.DOM.el.querySelectorAll('.c-form-link')
    this.isOpen = false

    this.initEvents()
  }

  initEvents() {
    each(this.DOM.buttons, btn => {
      btn.addEventListener('click', this.openModal.bind(this))
    })
    this.DOM.closeBtn.addEventListener('click', this.closeModal.bind(this))
    window.addEventListener('keyup', (e) => {
      e.keyCode === 27 ? this.isOpen ? this.closeModal() : false : false
    })
  }
  openModal() {
    if (this.isOpen) return false
    disableScroll.on()
    document.body.classList.add('modal-open')
    this.isOpen = true
    const tl = anime.timeline()

    tl
      .add({
        targets: this.DOM.el,
        opacity: [0, 1],
        scale: [1.1, 1],
        easing: 'easeOutCirc',
        duration: 500,
        complete: () => {
          this.DOM.el.classList.add('is-open')
        }
      })
      .add({
        targets: this.DOM.labels,
        translateX: ['-50%', 0],
        opacity: {
          value: [0, 1],
          easing: 'linear'
        },
        delay: function (el, i, l) {
          return i * 100
        },
        duration: 500,
        easing: 'easeOutCirc',
        offset: 200
      })
      .add({
        targets: this.DOM.pills,
        translateX: ['-50%', 0],
        translateY: ['50%', 0],
        translateZ: 0,
        opacity: {
          value: [0, 1],
          easing: 'linear'
        },
        delay: function (el, i, l) {
          return i * 100
        },
        duration: 500,
        easing: 'easeOutCirc',
        offset: 200
      })
  }

  closeModal() {
    if (!this.isOpen) return false
    anime({
      targets: this.DOM.el,
      opacity: 0,
      scale: 0.9,
      duration: 500,
      easing: 'easeOutCirc',
      complete: () => {
        disableScroll.off()
        this.DOM.el.classList.remove('is-open')
        document.body.classList.remove('modal-open')
        this.isOpen = false
      }
    })
  }
}
export default Modal