import {
  each
} from 'lodash'
import anime from 'animejs'

class Slideshow {
  constructor(el, options) {
    this.DOM = {
      el: el
    }
    // default options
    this.options = {
      slides: '.slide',
      paginationItems: '.pagination__item',
      priceSlides: '.price-slide'
    }
    // assign passing options
    Object.assign(this.options, options)
    // DOM
    this.DOM.slides = Array.from(document.querySelectorAll(this.options.slides))
    this.DOM.priceSlides = Array.from(document.querySelectorAll(this.options.priceSlides))
    
    this.DOM.videos = document.querySelectorAll('.slide video')
    this.DOM.paginationItems = Array.from(document.querySelectorAll(this.options.paginationItems))
    // HELPERS
    this.slidesTotal = this.DOM.slides.length
    this.current = 0
    this.previousHide = this.slidesTotal - 1
    this.previous = 0
    this.isAnimating = false
    this.next = 0
    this.prev = 0
    this.interval = null

    // init
  }

  init() {
    this.DOM.paginationItems[this.current].classList.add('pagination__item--current')
    this.DOM.slides[this.current].querySelector('video').play()
    // this.interval = setInterval(() => this.navigate(), 5000)

    each(this.DOM.paginationItems, (item, pos) => {
      item.addEventListener('click', () => {
        clearInterval(this.interval)
        this.navigate(pos)
        this.initInterval()
      })
    })
    this.initInterval()
  }

  play() {
    this.initInterval()
    this.DOM.paginationItems[this.current].classList.add('pagination__item--current')
    this.DOM.videos[this.current].play()
  }

  pause() {
    clearInterval(this.interval)
    this.DOM.paginationItems[this.current].classList.remove('pagination__item--current')
    this.DOM.videos[this.current].pause()
    this.DOM.videos[this.current].currentTime = 0
  }

  initInterval() {
    this.interval = setInterval(() => {
      const current = (this.current < this.slidesTotal - 1) ? this.current + 1 : 0
      this.navigate(current)
    }, 5000)
  }

  navigate(pos) {
    if (this.isAnimating) return false
    if (this.current === pos) return false
    anime.remove([this.DOM.slides[this.current], this.DOM.slides[this.previous], this.DOM.slides[this.previousHide]])
    this.isAnimating = true
    this.previous = this.current

    const tl = anime.timeline()
    anime({
      targets: this.DOM.slides[this.previous],
      translateX: '-100%',
      opacity: 0.3,
      easing: 'easeOutQuint',
      duration: 1000,
      begin: function (anim) {
        anim.animatables[0].target.querySelector('video').pause()
        anim.animatables[0].target.querySelector('video').currentTime = 0
      }
    })

    anime({
      targets: this.DOM.priceSlides[this.previous],
      translateX: '-100%',
      opacity: 0,
      easing: 'easeOutQuint',
      duration: 1000
    })
    this.current = pos
    this.DOM.paginationItems[this.previous].classList.remove('pagination__item--current')
    this.DOM.paginationItems[this.current].classList.add('pagination__item--current')

    tl
      .add({
        targets: this.DOM.slides[this.previousHide],
        translateX: '-200%',
        opacity: 0,
        easing: 'easeOutQuint',
        duration: 1000
      })
      .add({
        targets: this.DOM.slides[this.current],
        translateX: ['100%', 0],
        opacity: 1,
        easing: 'easeOutQuint',
        duration: 1000,
        offset: 0,
        complete: () => {
          this.previousHide = this.previous
          this.DOM.videos[this.current].play()
          this.isAnimating = false
        }
      })

      tl
      .add({
        targets: this.DOM.priceSlides[this.previousHide],
        translateX: '-200%',
        opacity: 0,
        easing: 'easeOutQuint',
        duration: 1000
      })
      .add({
        targets: this.DOM.priceSlides[this.current],
        translateX: ['100%', 0],
        opacity: 1,
        easing: 'easeOutQuint',
        duration: 1000,
        offset: 0
      })

  }
}

export default Slideshow