// Load application styles
import "styles/index.scss";
// import libs & partials
import { each } from "lodash";
import anime from "animejs";
import parallax from "scrollmonitor-parallax";
import eases from "eases";
import Slideshow from "./js/Slideshow";
import Uncover from "./js/Uncover";
import Slider from "./js/Slider";
import Modal from "./js/Modal";
import ExpanderDetails from "./js/ExpanderDetails";
import products from "../assets/data/products.json";
import "intersection-observer";
import lozad from "lozad";

// ================================
// DEFINE GLOBALS
// ================================
const brandColors = [
  "#064DFF",
  "#7CFF6A",
  "#FE00FF",
  "#FF6100",
  "#00FFC6",
  "#FFFF00"
];
const portfolioColors = [...brandColors];
portfolioColors.push("#000");

const uncoverOpts = {
  slicesTotal: 1,
  slicesColor: "#fff",
  orientation: "skew",
  slicesOrigin: {
    show: "right",
    hide: "right"
  }
};
const uncoverAnimation = {
  show: {
    slices: {
      duration: 800,
      easing: "easeInOutCubic",
      delay: (_, i) => i * 50
    },
    image: {
      duration: 1200,
      delay: 150,
      easing: "easeOutCubic",
      scale: [1.3, 1]
    }
  },
  hide: {
    slices: {
      duration: 600,
      delay: (_, i, t) => (t - i - 1) * 100,
      easing: "easeInOutCirc"
    }
  }
};

let items = Array.from(document.querySelectorAll(".uncover"));
let uncoverItems = Array.from(document.querySelectorAll(".uncover__item"));
let intro = document.querySelector(".portfolio__intro");
let uncoverArr = [];
let uncoverIsFirstTime = [];
let titles = document.querySelectorAll(".stagger-title");

const observerer = lozad();
observerer.observe();

// ================================
// ON DOM LOADED
// ================================
document.addEventListener("DOMContentLoaded", () => {
  // portfolio animation
  const observer = new IntersectionObserver(
    entries => {
      each(entries, (entry, pos) => {
        let index = uncoverItems.indexOf(entry.target);
        const video = items[index].querySelector(".uncover__img > video");
        if (entry.intersectionRatio > 0.3) {
          if (uncoverIsFirstTime[index]) {
            uncoverIsFirstTime[index] = false;
            uncoverArr[index].show(true, uncoverAnimation.show).then(() => {
              items[index].classList.add("is-animation-finished");
              if (video !== null) {
                video.play();
              }
            });
          } else {
            if (video !== null) {
              video.play();
            }
          }
        } else {
          if (!uncoverIsFirstTime[index]) {
            video.pause();
          }
        }
      });
    },
    {
      threshold: 0.3
    }
  );

  uncoverItems.forEach((item, pos) => {
    uncoverIsFirstTime[pos] = true;
    uncoverArr.push(new Uncover(item, uncoverOpts));
    observer.observe(item);
  });

  // portfolio intro animation
  const introObserver = new IntersectionObserver(
    entry => {
      const tl = anime.timeline();
      const portfolioBrand = intro.querySelector(".o-brand");

      if (entry[0].intersectionRatio > 0.6) {
        tl.add({
          targets: intro,
          translateY: [64, 0],
          opacity: 1,
          easing: "easeOutCirc"
        }).add({
          targets: portfolioBrand,
          color: portfolioColors,
          easing: "linear",
          offset: 100
        });
        introObserver.unobserve(entry[0].target);
      }
    },
    {
      threshold: 0.6
    }
  );
  introObserver.observe(intro);

  // slideshow intro animation
  let slideshowFirstTime = true;
  const slideshowEl = document.querySelector(".slideshow");
  const slideshow = new Slideshow({
    el: ".slides"
  });
  const slideshowObserver = new IntersectionObserver(
    entry => {
      if (entry[0].intersectionRatio >= 0.2) {
        if (slideshowFirstTime) {
          slideshowFirstTime = false;
          anime({
            targets: slideshowEl,
            translateY: [128, 0],
            opacity: {
              value: 1,
              easing: "linear"
            },
            duration: 1200,
            easing: "easeOutQuint",
            complete: () => {
              slideshow.init();
            }
          });
        } else {
          slideshow.play();
        }
      } else {
        if (!slideshowFirstTime) {
          slideshow.pause();
        }
      }
    },
    {
      threshold: 0.2
    }
  );
  slideshowObserver.observe(slideshowEl);

  setTimeout(() => {
    loaded();
  }, 2000);
});

function loaded() {
  // sliders
  const sliders = document.querySelector(".sliders");
  let slidersFirstTime = true;
  let slider = new Slider(sliders);

  // loader
  const loader = document.querySelector("#loader");
  const loaderBrand = loader.querySelector(".o-brand");
  const introTitle = document.querySelector(".intro__title");
  const introTitleStagger = document.querySelector(".stagger-title");
  const slidernav = document.querySelector(".slidernav");

  let tl = anime.timeline({
    complete: () => {
      document.body.classList.remove("is-loading");
      initBrand();
      initParallax();
      stickyHeader();
      // modal
      const modal = document.querySelector("#modal");
      new Modal(modal);
    }
  });

  tl.add({
    targets: loaderBrand,
    scaleX: [1, 0],
    scaleY: [1, 0],
    easing: "easeInOutQuint",
    duration: 500
  })
    .add({
      targets: loader,
      duration: 300,
      opacity: 0,
      easing: "linear",
      offset: "-=300",
      complete: () => {
        slider.init();
      }
    })
    .add({
      targets: introTitleStagger,
      duration: 500,
      delay: 500,
      opacity: {
        value: [0, 1],
        easing: "linear"
      },
      translateY: [60, 0],
      easing: "easeOutQuint"
    })
    .add({
      targets: slidernav,
      duration: 600,
      opacity: {
        value: [0, 1],
        easing: "linear"
      },
      translateY: ["100%", "0%"],
      easing: "easeOutQuint",
      offset: "-=300"
    });

  const NUM_ELEMENTS = titles.length;
  const TITLE_ANIM_DURATION = .67;

  titles.forEach((title, index) => {
    title.classList.add('animated');
    title.style.animationDuration = `${TITLE_ANIM_DURATION}s`;
    let titleDelay = index * (TITLE_ANIM_DURATION / NUM_ELEMENTS);

    title.style.animationDelay = `${titleDelay}s`;
  });

  // slider observer
  const sliderObserver = new IntersectionObserver(
    entry => {
      if (entry[0].intersectionRatio <= 0.2) {
        slidersFirstTime = false;
        slider.pause();
      } else {
        if (!slidersFirstTime) {
          slider.play();
        }
      }
    },
    {
      threshold: 0.2
    }
  );
  sliderObserver.observe(sliders);
}

// portfolio pills parallax effect
function initParallax() {
  const items = document.querySelectorAll(".o-pill--parallax");

  each(items, (item, index) => {
    let root = parallax.create(item);
    let itemParallax = root.add(item, {
      end: {
        y: index % 2 ? -300 : -200
      },
      easing: {
        y: eases.circIn
      }
    });
  });
}

// sticky header (optim with requestAnimationFrame)
function stickyHeader() {
  var lastScrollY = 0;
  var ticking = false;
  var windowHeight = window.innerHeight;
  var header = document.querySelector("#header");

  var update = function() {
    if (lastScrollY >= windowHeight) {
      header.classList.add("is-open");
    } else {
      header.classList.remove("is-open");
    }
    ticking = false;
  };

  var requestTick = function() {
    if (!ticking) {
      window.requestAnimationFrame(update);
      ticking = true;
    }
  };

  var onScroll = function() {
    lastScrollY = window.scrollY;
    requestTick();
  };

  window.addEventListener("scroll", onScroll);
}

// brands effects
function initBrand() {
  const brands = document.querySelectorAll(".o-brand--animated");
  const tl = anime.timeline({
    loop: true
  });

  each(brands, brand => {
    brand.addEventListener("mouseenter", playBrand);
    brand.addEventListener("mouseleave", pauseBrand);
  });
}

function playBrand(e) {
  anime({
    targets: e.target,
    color: brandColors,
    duration: brandColors.length * 100,
    loop: true,
    easing: "linear"
  });
}

function pauseBrand(e) {
  anime.remove(e.target);
  anime({
    targets: e.target,
    color: brandColors[brandColors.length - 1],
    duration: 100,
    easing: "linear",
    complete: () => {
      anime.remove(e.target);
    }
  });
}

// contact btn hover
const contact = document.querySelector(".intro__contact");
const contactSvg = contact.querySelector("#contactSvg");
const contactArrow = contact.querySelector("#contactArrow");
const contactEndPath = contact.dataset.morphPath;
const contactStartPath = contactSvg.getAttribute("d");

contact.addEventListener("mouseenter", contactEnter);
contact.addEventListener("mouseleave", contactLeave);

function contactEnter() {
  let tl = anime.timeline();
  tl.add({
    targets: contact,
    translateX: -20,
    translateY: 20,
    duration: 300,
    easing: "easeInOutCirc"
  })
    .add({
      targets: contactSvg,
      d: contactEndPath,
      duration: 300,
      easing: "easeInOutCirc",
      offset: 100
    })
    .add({
      targets: contactArrow,
      opacity: [0, 1],
      translateX: [-20, 0],
      translateY: [20, 0],
      duration: 300,
      easing: "easeInOutCirc",
      offset: 100
    });
}

function contactLeave() {
  let tl = anime.timeline();
  tl.add({
    targets: contactArrow,
    opacity: 0,
    translateX: -20,
    translateY: 20,
    duration: 300,
    easing: "easeInOutCirc"
  })
    .add({
      targets: contactSvg,
      d: contactStartPath,
      duration: 300,
      easing: "easeInOutCirc",
      offset: 100
    })
    .add({
      targets: contact,
      translateX: 0,
      translateY: 0,
      duration: 300,
      easing: "easeInOutCirc",
      offset: 100
    });
}

let observer = new IntersectionObserver(
  function(entries) {
    if (entries[0].intersectionRatio === 0)
      document.querySelector(".contact--mobile").classList.add("sticked");
    else if (entries[0].intersectionRatio === 1)
      document.querySelector(".contact--mobile").classList.remove("sticked");
  },
  { threshold: [0, 1] }
);

observer.observe(document.querySelector("#nav-container-top"));

// portfolio expander
let gridItems = [];
const portfolioItems = document.querySelectorAll(".portfolio__item--expander");
each(portfolioItems, (item, index) => {
  const product = products.filter(product => product.item === index);
  gridItems.push(new ExpanderDetails(item, product));
});
