import anime from 'animejs'
import disableScroll from 'disable-scroll'

class Expander {
  constructor(el, products) {
    this.DOM = {
      el: el
    }
    this.isDesktop = window.matchMedia('(min-width: 992px)').matches
    let expanderTemplate = `
      <div class="expander__bg"></div>
      <div class="expander__wrapper">
        <div class="expander__wrapper__bg">
          <div class="expander__pills">
            <div class="o-pill o-pill--anybuddy-yellow o-pill--top">
              <svg height="438" width="438" viewBox="0 0 438 438">
                <use xlink:href="#pill"></use>
              </svg>
            </div>
            <div class="o-pill o-pill--black o-pill--bottom">
              <svg height="438" width="438" viewBox="0 0 438 438">
                <use xlink:href="#pill"></use>
              </svg>
            </div>
          </div>
        </div>
        <span class="o-close"></span>
        <div class="expander__content">
          <div class="expander__pricing"></div>
          <div class="products">`
    for (let i = 0; i < products.length; i++) {
      expanderTemplate += `<div class="product">
        <div class="product__media">
          <div class="product__picture">
            <img src="${products[i].picture}" alt="">
          </div>
          <i>${products[i].media}</i>
        </div>
        <div class="product__infos">
          <span class="product__name">${products[i].name}</span>
          <span class="product__price">${products[i].pricing}</span>
        </div>
      </div>`
    }
    expanderTemplate += `</div>
        </div>
        <div class="expander__picture">
          <img src="">
        </div>
      </div>
    `

    this.DOM.expander = document.createElement('div')
    this.DOM.expander.className = 'expander'
    this.DOM.expander.innerHTML = expanderTemplate
    document.body.appendChild(this.DOM.expander)
    this.init()
  }
  init() {
    this.isAnimating = false
    this.isOpen = false
    this.DOM.bg = this.DOM.expander.querySelector('.expander__bg')
    this.DOM.pricing = this.DOM.expander.querySelector('.expander__pricing')
    this.DOM.picture = this.DOM.expander.querySelector('.expander__picture > img')
    this.DOM.expanderWrapper = this.DOM.expander.querySelector('.expander__wrapper')
    this.DOM.expanderWrapperBg = this.DOM.expander.querySelector('.expander__wrapper__bg')
    this.DOM.products = this.DOM.expander.querySelectorAll('.product')
    this.DOM.pills = {
      top: this.DOM.expander.querySelector('.o-pill--top'),
      bottom: this.DOM.expander.querySelector('.o-pill--bottom')
    }
    this.pills = {
      top: this.getComputedTranslateXY(this.DOM.pills.top),
      bottom: this.getComputedTranslateXY(this.DOM.pills.bottom)
    }
    this.DOM.close = this.DOM.expander.querySelector('.o-close')
    this.DOM.close.addEventListener('click', () => this.close())
    document.addEventListener('keyup', (e) => {
      if (this.isOpen) {
        if (e.keyCode === 27) this.close()
      }
    })
  }
  fill(info) {
    this.DOM.picture.src = info.picture
    this.DOM.pricing.innerHTML = info.pricing
    this.DOM.bg.style.backgroundColor = info.bgcolor
  }
  getComputedTranslateXY(obj) {
    const transArr = []
    if (!window.getComputedStyle) return
    const style = getComputedStyle(obj)
    const transform = style.transform || style.webkitTransform || style.mozTransform
    let mat = transform.match(/^matrix3d\((.+)\)$/)
    if (mat) return parseFloat(mat[1].split(', ')[13])
    mat = transform.match(/^matrix\((.+)\)$/)
    mat ? transArr.push(parseFloat(mat[1].split(', ')[4])) : 0
    mat ? transArr.push(parseFloat(mat[1].split(', ')[5])) : 0
    return transArr
  }
  getExpanderRect() {
    return {
      expanderPricing: this.DOM.pricing.getBoundingClientRect(),
      expanderPicture: this.DOM.picture.getBoundingClientRect(),
      expanderWrapper: this.DOM.expanderWrapper.getBoundingClientRect(),
      detailsPricing: this.DOM.detailsPricing.getBoundingClientRect(),
      detailsPicture: this.DOM.detailsPicture.getBoundingClientRect(),
      detailsWrapper: this.DOM.detailsWrapper.getBoundingClientRect()
    }
  }
  open(data) {
    if (this.isAnimating) return false
    disableScroll.on()
    this.isAnimating = true

    this.DOM.expander.classList.add('is-open')
    document.body.classList.add('modal-open')

    this.DOM.detailsPricing = data.detailsPricing
    this.DOM.detailsPicture = data.detailsPicture
    this.DOM.detailsWrapper = data.detailsWrapper

    this.DOM.detailsPricing.style.opacity = 0
    this.DOM.detailsPicture.style.opacity = 0

    const rect = this.getExpanderRect()

    this.DOM.expanderWrapper.style.opacity = 1

    this.DOM.expanderWrapperBg.style.transform = `translateX(${rect.detailsWrapper.left - rect.expanderWrapper.left}px) translateY(${rect.detailsWrapper.top - rect.expanderWrapper.top}px) scaleX(${rect.detailsWrapper.width / rect.expanderWrapper.width}) scaleY(${rect.detailsWrapper.height / rect.expanderWrapper.height})`

    this.DOM.picture.style.transform = `translateX(${rect.detailsPicture.left - rect.expanderPicture.left}px) translateY(${rect.detailsPicture.top - rect.expanderPicture.top}px)`
    this.DOM.picture.style.opacity = 1

    if (this.isDesktop) {
      this.DOM.pricing.style.transform = `translateX(${rect.detailsPricing.right - rect.expanderPricing.right}px) translateY(${rect.detailsPricing.top - rect.expanderPricing.top}px)`
      this.DOM.pricing.style.opacity = 1
    }

    const tl = anime.timeline({
      complete: () => {
        this.isOpen = true
        this.isAnimating = false
        anime.remove(tl)
      }
    })

    if (this.isDesktop) {
      tl
        .add({
          targets: [this.DOM.expanderWrapperBg, this.DOM.pricing, this.DOM.picture],
          duration: '600',
          borderRadius: (el, i) => this.isDesktop ? (i === 0 ? 20 : 0) : 0,
          easing: 'easeInOutCirc',
          translateX: 0,
          translateY: 0,
          scaleX: 1,
          scaleY: 1,
          offset: 0,
          complete: () => {
            anime({
              targets: this.DOM.expanderWrapperBg,
              boxShadow: ['0 0 0 0 rgba(0, 0, 0, 0.20)', '-3px 2px 24px 0 rgba(0, 0, 0, 0.20)'],
              duration: 300,
              easing: 'linear'
            })
          }
        })
    } else {
      tl
        .add({
          targets: [this.DOM.expanderWrapperBg, this.DOM.picture],
          duration: '600',
          borderRadius: (el, i) => this.isDesktop ? (i === 0 ? 20 : 0) : 0,
          easing: 'easeInOutCirc',
          translateX: 0,
          translateY: 0,
          scaleX: 1,
          scaleY: 1
        })
        .add({
          targets: this.DOM.pricing,
          opacity: 1,
          duration: 300,
          easing: 'linear',
          offset: 400
        })
    }

    tl
      .add({
        targets: [this.DOM.bg, this.DOM.close],
        opacity: 1,
        duration: '400',
        delay: (el, i, l) => i * 200,
        easing: 'linear',
        offset: 300
      })
      .add({
        targets: [this.DOM.pills.top, this.DOM.pills.bottom],
        translateX: (el, i) => !i ? [this.pills.top[0], 0] : [this.pills.bottom[0], 0],
        translateY: (el, i) => !i ? [this.pills.top[1], 0] : [this.pills.bottom[1], 0],
        duration: 600,
        easing: 'easeInOutCirc',
        offset: '-=800'
      })
      .add({
        targets: this.DOM.products,
        opacity: [0, 1],
        translateY: ['48', 0],
        duration: '800',
        delay: (el, i, l) => i * 100,
        easing: 'easeOutCirc',
        offset: '-=400'
      })
  }
  close() {
    if (this.isAnimating) return false
    this.isAnimating = true

    const rect = this.getExpanderRect()
    const tl = anime.timeline({
      complete: () => {
        disableScroll.off()
        this.DOM.detailsPricing.style.opacity = 1
        this.DOM.detailsPicture.style.opacity = 1
        document.body.classList.remove('modal-open')
        document.body.removeChild(this.DOM.expander)
        anime.remove(tl)
        this.isOpen = false
        this.isAnimating = false
      }
    })

    tl
      .add({
        targets: [this.DOM.pills.top, this.DOM.pills.bottom],
        translateX: (el, i) => !i ? [0, this.pills.top[0]] : [0, this.pills.bottom[0]],
        translateY: (el, i) => !i ? [0, this.pills.top[1]] : [0, this.pills.bottom[1]],
        duration: 600,
        easing: 'easeInOutCirc'
      })
      .add({
        targets: this.DOM.products,
        opacity: [1, 0],
        translateY: 48,
        duration: 300,
        delay: (el, i, l) => (l - i) * 50,
        easing: 'easeOutCirc',
        offset: '-=300'
      })
      .add({
        targets: this.DOM.expanderWrapperBg,
        duration: 400,
        translateX: rect.detailsWrapper.left - rect.expanderWrapper.left,
        translateY: rect.detailsWrapper.top - rect.expanderWrapper.top,
        scaleX: rect.detailsWrapper.width / rect.expanderWrapper.width,
        scaleY: rect.detailsWrapper.height / rect.expanderWrapper.height,
        borderRadius: 0,
        easing: 'easeOutCirc',
        offset: '-=200'
      })
      .add({
        targets: this.DOM.pricing,
        duration: 400,
        translateX: this.isDesktop ? rect.detailsPricing.right - rect.expanderPricing.right : 0,
        translateY: this.isDesktop ? rect.detailsPricing.top - rect.expanderPricing.top : 0,
        opacity: this.isDesktop ? 1 : 0,
        easing: 'easeOutCirc',
        offset: '-=400'
      })
    tl
      .add({
        targets: this.DOM.picture,
        duration: 400,
        translateX: rect.detailsPicture.left - rect.expanderPicture.left,
        translateY: rect.detailsPicture.top - rect.expanderPicture.top,
        easing: 'easeOutCirc',
        offset: '-=400'
      })
      .add({
        targets: [this.DOM.expanderWrapperBg, this.DOM.pricing, this.DOM.picture],
        opacity: 0,
        duration: 400,
        easing: 'linear',
        offset: '-=300'
      })
      .add({
        targets: [this.DOM.bg, this.DOM.close],
        opacity: 0,
        duration: 300,
        delay: (el, i, l) => (l - i) * 100,
        easing: 'linear',
        offset: 100
      })
  }
}
export default Expander