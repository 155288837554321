/*
 * animation steps
 * hide current
 * shape morphing + set project color
 * show new current
 */
import { each } from "lodash";
import Warp from "warpjs";
import anime from "animejs";
// import Snap from './SnapSVG'
class Slider {
  constructor(el) {
    this.DOM = {
      el: el
    };
    this.options = {
      sliders: {
        duration: 600,
        easing: { in: "easeOutQuint", out: "easeOutQuad" }
      }
    };
  }

  init() {
    this.DOM.sliders = Array.from(this.DOM.el.querySelectorAll(".slider"));
    this.slidersTotal = this.DOM.sliders.length;
    this.DOM.nav = this.DOM.el.querySelector(".slidernav");
    this.DOM.navItems = this.DOM.nav.querySelectorAll("button");
    this.DOM.intro = document.querySelector(".intro");
    this.DOM.shape = document.querySelector("#shape");
    this.DOM.rays = document.querySelectorAll(".rays");
    this.DOM.foregroundRays = this.DOM.intro.querySelector("#foregroundRays");
    this.DOM.backgroundRays = this.DOM.intro.querySelector("#backgroundRays");
    this.DOM.brand = document.querySelector("#brand");
    this.DOM.contact = document.querySelector(".intro__contact");
    this.DOM.slidersContainer = document.querySelector('.sliders')
    this.DOM.raysBlock = document.querySelector(".rays-element");
    this.current = 0;
    this.slidersData = this.DOM.sliders.map(slider => ({
      primaryColor: slider.dataset.primaryColor,
      secondaryColor: slider.dataset.secondaryColor,
      thirtyColor: slider.dataset.thirtyColor,
      path: slider.dataset.path
    }));
    this.offset = 0;
    this.interval = null;
    this.initAnimation();
    this.initEvents();
  }
  initAnimation() {
    this.isAnimating = true;
    let tl = anime.timeline({
      complete: () => {
        this.isAnimating = false;
        this.initInterval();
      }
    });

    if (window.matchMedia("(min-width: 992px)").matches) {
      this.DOM.slidersContainer.style.backgroundColor = this.slidersData[0].secondaryColor;
    }

    tl.add({
      targets: this.DOM.rays,
      scale: {
        value: 0,
        duration: 500
      },
      opacity: {
        value: 0,
        duration: 300
      },
      easing: "easeInOutElastic",
      complete: () => {
        anime({
          targets: this.DOM.backgroundRays,
          fill: this.slidersData[0].thirtyColor,
          duration: 0
        });
        anime({
          targets: this.DOM.foregroundRays,
          fill: this.slidersData[0].secondaryColor,
          duration: 0
        });
      }
    }).add({
      targets: this.DOM.rays,
      scale: {
        value: [0.5, 1.15],
        duration: 500
      },
      opacity: {
        value: 1,
        duration: 500
      },
      easing: "easeInOutElastic",
      delay: 300
    });
  }
  initEvents() {
    this.DOM.sliders[this.current].querySelector("video").currentTime = 0;
    this.DOM.sliders[this.current].querySelector("video").play();

    each(this.DOM.navItems, (item, pos) => {
      this.DOM.sliders[pos].querySelector("video").currentTime = 0;

      item.addEventListener("click", () => {
        this.DOM.sliders[pos].querySelector("video").currentTime = 0
        clearInterval(this.interval);
        this.navigate(pos);
        this.initInterval();
      });
    });
  }

  play() {
    this.initInterval();
  }

  pause() {
    clearInterval(this.interval);
  }

  initInterval() {
    this.interval = setInterval(() => {
      const current =
        this.current < this.slidersTotal - 1 ? this.current + 1 : 0;
      this.navigate(current);
    }, 3000);
  }

  navigate(pos) {
    if (this.isAnimating) return false;
    if (this.current === pos) return false;
    this.isAnimating = true;
    this.DOM.navItems[this.current].classList.remove("is-active");
    this.DOM.navItems[pos].classList.add("is-active");

    if (window.matchMedia("(min-width: 992px)").matches) {
      this.DOM.slidersContainer.style.backgroundColor = this.slidersData[pos].secondaryColor;
    }
    
    this.DOM.sliders[pos].querySelector("video").currentTime = 0;

    let tl = anime.timeline({
      complete: () => {
        this.isAnimating = false;
        this.current = pos;
      }
    });

    tl.add({
      targets: this.DOM.rays,
      scale: {
        value: 2,
        duration: 500
      },
      opacity: {
        value: [1, 0],
        duration: 200,
        delay: 200
      },
      easing: "easeInQuint",
      complete: () => {
        anime({
          targets: this.DOM.backgroundRays,
          fill: this.slidersData[pos].thirtyColor,
          duration: 0
        });
      }
    })
      .add({
        targets: this.DOM.intro,
        backgroundColor: this.slidersData[pos].primaryColor,
        easing: "linear",
        duration: 300,
        offset: 400
      })
      .add({
        targets: this.DOM.contact,
        color: this.slidersData[pos].secondaryColor,
        easing: "linear",
        duration: 300,
        offset: 400,
        complete: () => {
          this.DOM.sliders[this.current].classList.remove("slider--current");
          this.DOM.sliders[pos].classList.add("slider--current");
          if (this.DOM.sliders[pos].querySelector("video") !== null) {
            this.DOM.sliders[pos].querySelector("video").currentTime = 0
            this.DOM.sliders[pos].querySelector("video").play();
          }
        }
      })
      .add({
        targets: this.DOM.rays,
        scale: {
          value: [0.2, 1.13],
          duration: 500
        },
        opacity: {
          value: 1,
          duration: 500
        },
        easing: "easeInOutElastic",
        delay: 300
      })
      .add({
        targets: this.DOM.shape,
        d: this.slidersData[pos].path,
        duration: 1300,
        easing: "easeInOutElastic",
        offset: 0
      });
  }
}

export default Slider;
