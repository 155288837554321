import Expander from './Expander';

class ExpanderDetails {
  constructor(el, products) {
    this.DOM = {
      el: el
    }
    this.isDesktop = window.matchMedia('(min-width: 992px)').matches
    this.DOM.item = this.DOM.el.querySelector('.uncover__item')
    this.DOM.details = this.DOM.item.querySelector('.details')
    this.DOM.detailsMobile = this.DOM.el.querySelector('.uncover__details__mobile')
    this.DOM.detailsBtn = this.isDesktop ? this.DOM.details.querySelector('.btn--details') : this.DOM.detailsMobile.querySelector('.btn--details')
    this.DOM.detailsPricing = this.isDesktop ? this.DOM.details.querySelector('.details__pricing') : this.DOM.detailsMobile.querySelector('.details__pricing')
    this.DOM.detailsPicture = this.DOM.details.querySelector('.details__picture > img')
    this.products = products
    this.layout()
    this.initEvents()
  }
  layout() {
    this.info = {
      pricing: this.DOM.detailsPricing.innerHTML,
      picture: this.DOM.detailsPicture.src,
      bgcolor: this.DOM.details.dataset.bgcolor
    }
  }
  initEvents() {
    this.DOM.detailsBtn.addEventListener('click', () => this.open())
  }
  open() {
    this.expander = new Expander(this.DOM.item, this.products)
    this.expander.fill(this.info)
    this.expander.open({
      detailsPricing: this.DOM.detailsPricing,
      detailsPicture: this.DOM.detailsPicture,
      detailsWrapper: this.DOM.details
    })
  }
}

export default ExpanderDetails